import React from 'react';
import './App.css';
import SyntaxHighlighter from "react-syntax-highlighter"
import { dracula } from "react-syntax-highlighter/dist/esm/styles/hljs";


function App() {
  const x = 
  `const a = 5;
if (a === 0) {
  console.log(a);
}`
console.log(Intl.DateTimeFormat().resolvedOptions().timeZone)
  return (
    <div className="App">
      <header className="App-header">
        <p style={{backgroundColor: '#00796B', marginTop: '20px', marginLeft: '20px', marginRight: '20px', marginBottom: '20px', paddingTop: '20px', paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px', borderRadius: "8px" }}>
         Welcome to AndroidDevSunny One stop solution for all things Android 
        </p>
       <SyntaxHighlighter children={x} language='typescript' style={{...dracula}}></SyntaxHighlighter>
      </header>
    </div>
  );
}

export default App;
